var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "product-list-grid" },
    [
      _vm.totItems > 0
        ? [
            _c(
              "div",
              { staticStyle: { "max-height": "100%" } },
              [
                _c(
                  "v-row",
                  _vm._l(_vm.products, function(article) {
                    return _c(
                      "v-col",
                      {
                        key: article.id,
                        attrs: { cols: "6", sm: "4", md: "3", lg: "2" }
                      },
                      [_c("ArticleCard", { attrs: { article: article } })],
                      1
                    )
                  }),
                  1
                )
              ],
              1
            ),
            _vm.hasMoreItems
              ? _c(
                  "v-btn",
                  {
                    directives: [
                      {
                        name: "intersect",
                        rawName: "v-intersect",
                        value: _vm.onIntersect,
                        expression: "onIntersect"
                      }
                    ],
                    staticClass: "mt-3",
                    attrs: {
                      block: "",
                      text: "",
                      large: "",
                      depressed: "",
                      rounded: "",
                      color: "primary",
                      loading: _vm.loading
                    },
                    on: { click: _vm.loadMore }
                  },
                  [_vm._v(" " + _vm._s(_vm.$t("recipes.showMore")) + " ")]
                )
              : _vm._e()
          ]
        : _vm.loading
        ? _c(
            "div",
            { staticClass: "product-list-grid-skeleton" },
            [
              _c(
                "v-row",
                _vm._l(12, function(index) {
                  return _c(
                    "v-col",
                    {
                      key: index,
                      attrs: { cols: "12", sm: "6", md: "6", lg: "3", xl: "4" }
                    },
                    [
                      _c("v-skeleton-loader", {
                        attrs: { type: "image, list-item-three-line" }
                      })
                    ],
                    1
                  )
                }),
                1
              )
            ],
            1
          )
        : _c(
            "h4",
            {
              staticClass: "py-8 default--text text-center font-weight-semibold"
            },
            [_vm._v(" Nessun articolo trovato ")]
          )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }