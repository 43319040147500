<template>
  <div class="product-list-grid">
    <template v-if="totItems > 0">
      <div style="max-height:100%">
        <v-row
          ><v-col
            cols="6"
            sm="4"
            md="3"
            lg="2"
            v-for="article in products"
            :key="article.id"
          >
            <ArticleCard :article="article" /> </v-col
        ></v-row>
      </div>
      <v-btn
        block
        text
        large
        depressed
        rounded
        color="primary"
        v-intersect="onIntersect"
        :loading="loading"
        @click="loadMore"
        v-if="hasMoreItems"
        class="mt-3"
      >
        {{ $t("recipes.showMore") }}
      </v-btn>
    </template>
    <div v-else-if="loading" class="product-list-grid-skeleton">
      <v-row>
        <v-col
          v-for="index in 12"
          :key="index"
          cols="12"
          sm="6"
          md="6"
          lg="3"
          xl="4"
        >
          <v-skeleton-loader
            type="image, list-item-three-line"
          ></v-skeleton-loader>
        </v-col>
      </v-row>
    </div>
    <h4 class="py-8 default--text text-center font-weight-semibold" v-else>
      Nessun articolo trovato
    </h4>
  </div>
</template>
<script>
//import ProductGrid from "./ProductGrid.vue";
import AnalyticsService from "~/service/analyticsService";

import { mapGetters } from "vuex";
import mksCmService from "@/commons/service/mksCmService";
import ArticleCard from "@/components/article/ArticleCard.vue";

export default {
  name: "ProductList",
  props: {
    parentCategoryId: { type: Number, required: false },
    categoryId: { type: Number, required: false },
    query: { type: String, required: false },
    barcode: { type: String, required: false },
    mode: { type: String, required: false },
    hidePromoFilter: { type: Boolean, default: false },
    hideFilterString: { type: String, required: false },
    hideSort: { type: Boolean, default: false }
  },
  components: {
    ArticleCard
  },
  jsonld() {
    if (this.products) {
      var items = this.products.map((item, index) => ({
        "@type": "ListItem",
        position: index + 1,
        url: process.env.VUE_APP_EBSN_URL + "/" + item.slug
      }));
      return {
        "@context": "https://schema.org",
        "@type": "ItemList",
        itemListElement: items
      };
    }
  },
  data() {
    return {
      products: [],
      facets: [],
      pager: {},
      page: 1,
      busy: false,
      loading: false,
      filteredProductsCount: 0,
      key: 0
    };
  },
  computed: {
    queryLabel() {
      return this.barcode ? this.barcode : this.query;
    },
    currentRouteName() {
      return this.$route.name;
    },
    totItems() {
      return this.pager && this.pager.totItems ? this.pager.totItems : 0;
    },
    ...mapGetters({
      promoFilter: "category/promoFilter",
      newFilter: "category/newFilter",
      sortFilter: "category/sortFilter",
      pageFilter: "category/pageFilter"
    }),

    facetsFilter: {
      get() {
        return this.$store.state.category.filters.facets;
      },
      set() {
        // this.$store.commit("app/updatedDrawerLeft", value);
      }
    },

    hasMoreItems() {
      if (this.pager) {
        return this.pager.totPages > this.pager.selPage;
      } else {
        return false;
      }
    }
  },
  methods: {
    handleSortFilter(sort) {
      this.$store.dispatch("category/setFilterSort", sort);
      this.fetchProducts();
    },
    handlePromoFilter(promo) {
      this.$store.dispatch("category/setFilterPromo", promo);
      this.fetchProducts();
    },
    handleNewFilter(onlyNew) {
      this.$store.dispatch("category/setFilterNew", onlyNew);
      this.fetchProducts();
    },
    handleFacetFilter(filter) {
      this.$store.dispatch("category/setFilterFacets", {
        facets: filter.facets,
        parameterName: filter.parameterName
      });
      this.fetchProducts();
    },
    handleFacetFilterMobile(selectedFacetsXS) {
      this.$store.dispatch("category/setFilterFacetsMobile", {
        facets: selectedFacetsXS
      });
      this.fetchProducts();
    },
    handlePageFilter(page) {
      var _this = this;
      this.$store.dispatch("category/setFilterPage", page);
      //await di this.fetchProduts
      this.fetchProducts();
      //oppure mettere goTo(0) dentro timeout
      setTimeout(function() {
        _this.$vuetify.goTo(0);
      }, 300);
    },

    onIntersect(entries) {
      if (entries[0].isIntersecting) {
        this.loadMore();
      }
    },
    loadMore() {
      if (this.hasMoreItems) {
        this.$store.dispatch("category/setFilterPage", this.pageFilter + 1);
        this.fetchProducts(true);
      }
    },
    async fetchProducts(append) {
      this.loading = true;
      let response = await mksCmService.searchArticle({
        parent_article_type_id: [1, 2],
        q: this.query,
        barcode: this.barcode,
        page: this.pageFilter,
        page_size: process.env.VUE_APP_PRODUCT_LIST_PAGE_SIZE,
        sort: "pub_date_des"
      });

      // Se ho letto il barcode e mi torna un Prodotto, navigo diretto al Dettaglio
      // if (this.barcode && response.data.articles.length > 0) {
      //   this.$router.push({
      //     name: response.data.articles[0].articleType.name,
      //     params: { pageName: response.data.articles[0].slug }
      //   });
      // } else
      if (append) {
        this.products = [...this.products, ...response.data.articles];
      } else {
        this.products = response.data.articles;
      }
      this.key++;

      this.pager = response.data.page;
      this.facets = response.data.facets;

      this.$emit("productsCount", this.pager.totItems);
      this.filteredProductsCount = response.data.page.totItems;
      if (this.pager.searchedText) {
        this.$emit("searchedText", this.pager.searchedText);
      }
      this.loading = false;
      // AnalyticsService.viewProducts(
      //   response.articles,
      //   this.itemListName || "ProductListGrid",
      //   this.pageFilter,
      //   this.query
      // );
    },
    selectFilter(e) {
      console.log(e);
    },
    handleClick(payload) {
      AnalyticsService.clickProduct(
        payload.product,
        this.itemListName || "ProductListGrid",
        payload.index
      );
    }
  },
  async created() {
    if (this.$vuetify.breakpoint.xsOnly) {
      let page = this.pageFilter;
      for (let i = 1; i <= page; i++) {
        this.$store.dispatch("category/setFilterPage", i);
        await this.fetchProducts(true);
      }
    } else {
      this.fetchProducts();
    }
  }
};
</script>
<style lang="scss">
.product-list-grid {
  .product-count {
    font-size: 18px;
    margin-bottom: 16px;
    span {
      font-weight: bold;
    }
  }
}
</style>
